@import "../../styles/variables";

.addCompanyWrapper{
    width: 94%;
    margin: 1.5rem auto 0px;
    padding: 1.1rem 1.9rem 1.1rem 1.9rem;
    background-color: $white;
    border-radius: $borderRadiusFourteen;
    position: relative;

    p{
        font: 500 1.25rem $fontFamily;
    }

    form{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.9rem;
        margin-top: 1rem;
        .inputFieldsWrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;

            .inputAndErrorWrapper{
                display: flex;
                flex-direction: column;
                width: 49%;
            }
        }
    }
}