@import "../../styles/variables";

.pageNotFoundWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p{
        &:nth-child(1){
            font: 700 3.75rem $fontFamily;
        }

        &:nth-child(2){
            font: 1000 15rem $fontFamily;
            color: $themeBackground;
        }

        &:nth-child(3){
            font: 600 1.6rem $fontFamily;
        }
        &:nth-child(4){
            font: 400 1rem $fontFamily;
            color: $textDarkestGrayT;
        }
    }

    button{
        width: 18rem;
        height: 2.75rem;
        margin-top: 2.5rem;
        background: $themeBackground;
        border: none;
        border-radius: $borderRadiusTen;
        color: $white;
        cursor: pointer;
        
        span{
            font: 500 15px $fontFamily;
            border-radius: $borderRadiusSeven;
            text-decoration-line: underline;
        }
    }
}