@import "../../styles/variables";

.searchContentWrapper{
    width: 100%;
    height: 100%;
    
    p{
        margin: 1rem 0rem 0rem 2rem;
        font: 400 2rem $fontFamily;
        color: $textDarkGray;
    }
}


@import "styles/variables";

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;

  button {
    width: 150px;
    height: 27px;
    background-color: #ff0200;
    font:500 10px $fontFamily;
    gap: 5px;
    border-radius: 4px;
    align-self: flex-end;
    margin-bottom: 25px;

    &:hover{
        border: 1px solid $themeBackground;
    }
  }

  .noDataWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem auto 1rem;
    padding-bottom: 2rem;
    color: $textLightGray;
    border-bottom: 1px solid $textLightGray;

    p {
      font: 400 1rem $fontFamily;
    }
  }
}

.tableEditButtonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: 100%;

  button {
    background-color: #ff0200;
    justify-content: center;
    align-self: center;
    width: 46px;
    height: 23px;
    margin: 0px;
    gap: 2px;
    font:500 8px $fontFamily;

    &:hover{
        border: 1px solid $themeBackground;
    }
  }
}
