form {
    width: 80%;
  margin-top: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.heading {
  font: 500 1rem "Poppins";
}
