@import "styles/variables";

.modalText {
  font: 500 25px $fontFamily;
  margin-top: 3.5rem;
}

.modalSubtitle {
  font: 400 1rem $fontFamily;
}
.modalCustomButton {
  width: 18rem;
  height: 2.75rem;
  background: $themeBackground;
  border: none;
  border-radius: $borderRadiusTen;
  color: $white;
  cursor: pointer;

  span {
    font: 500 15px $fontFamily;
    border-radius: $borderRadiusSeven;
    text-decoration-line: underline;
  }
  &:hover {
    background: $white;
    border: 1px solid $themeBackground;
    color: $themeBackground;
  }
}
