@import "styles/variables";

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;

  .noDataWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem auto 1rem;
    padding-bottom: 2rem;
    color: $textLightGray;
    border-bottom: 1px solid $textLightGray;

    p {
      font: 400 1rem $fontFamily;
    }
  }
}

.tableEditButtonWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.downloadListBtn {
  width: 182px !important;
  height: 33px;
  font: 500 12px $fontFamily;
  gap: 5px;
  border-radius: 4px;
  background: $themeBackground;
  border: none;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 25px;

  &:hover {
    background: $white;
    color: $themeBackground;
    border: 1px solid $themeBackground;
  }
}

.allDriversHeaderWrapper {
  display: flex;

  .allDriversHeaderWrapperLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font: 400 20px $fontFamily;
      span {
        font: 500 20px $fontFamily;
      }
    }
  }

  .allDriversHeaderWrapperRight {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
}
