@import "styles/variables";

//Payment Modal
.termsAndBillingWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
  
    .termsAndConditionsWrapper{
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 19.5rem;
  
      p{
        &:nth-child(1){
          font: 600 1.25rem $fontFamily;
        }
  
        &:nth-child(2){
          font: 400 1rem $fontFamily;
          color: $textDarkestGrayT;
        }
      }
    }
  
    .billingDetailsWrapper{
      display: flex;
      flex-direction: column;
      gap: 11px;
      width: 21.5rem;
  
      .subTotal{
        display: flex;
        justify-content: space-between;
  
        p{
          font: 400 1.25rem $fontFamily;
          color: $textDarkestGrayT;
        }
      }
      .tax{
        display: flex;
        justify-content: space-between;
  
        p{
          font: 400 1.25rem $fontFamily;
          color: $textDarkestGrayT;
        }
      }
      .total{
        display: flex;
        justify-content: space-between;
  
        p{
          font: 500 1.25rem $fontFamily;
        }
      }
    }
  
  }
  
  .divider{
    width: 100%; /* Takes up 80% of the container's width */
    margin: 0 auto; /* Center the divider horizontally within the container */
    height: 0.5px; /* Adjust the divider's height as needed */
    background-color: #E2E2E2; /* Divider color */
  }

  .warningText{
    font: 400 1rem $fontFamily;

    span{
      color: $themeBackground;
      font-weight: 800;
    }
  }