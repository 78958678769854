@import "styles/variables";

.modalText {
  font: 500 25px $fontFamily;
  align-self: center;

  span {
    font: 700 25px $fontFamily;
    color: $themeBackground;
  }
}

.inputFieldsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .inputAndErrorWrapper {
    display: flex;
    flex-direction: column;
    width: 48.5%;
  }
}

.statisticsWrapper {
  width: 100%;
  display: flex;
  background-color: $inputBackground;
  border-radius: $borderRadiusEight;
  height: 104px;
  overflow: hidden;

  .allDrivers {
    width: 32%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .topBar {
      width: 100%;
      height: 32px;
      background-color: $themeBackground;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font: 400 15px $fontFamily;
        color: $white;
      }
    }
    .numberContent {
        width: 100%;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
      p {
        font: 400 1.9rem $fontFamily;
        color: #555454;
      }
    }
  }
}
