@import "styles/variables";

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;

  .backArrowWrapper {
    display: flex;
    justify-content: space-between;

    .backArrowLeftWrapper {
      display: flex;
      gap: 1rem;
      p {
        font: 500 1.25rem $fontFamily;
      }
    }
  }

  .noDataWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem auto 1rem;
    padding-bottom: 2rem;
    color: $textLightGray;
    border-bottom: 1px solid $textLightGray;

    p {
      font: 400 1rem $fontFamily;
    }
  }
}

.tableEditButtonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.downloadListBtn {
  width: 160px !important;
  height: 27px;
  font: 500 10px $fontFamily;
  gap: 5px;
  border-radius: 4px;
  background: $themeBackground;
  border: none;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: $white;
    color: $themeBackground;
    border: 1px solid $themeBackground;
  }
}
.contentAndSearchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0rem;

  .actionButtonAndSearchBar {
    width: 40%;
    display: flex;
    gap: 1.2rem;
    flex-direction: column;

    .actionButtonsWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }
  .addressAndPhoneWrapper {
    p {
      font: 500 12px $fontFamily;
      span {
        font: 800 12px $fontFamily;
      }
    }
  }
}
