@import "styles/variables";

.modalText {
    font: 500 25px $fontFamily;
    align-self: center;
  
    span {
      font: 700 25px $fontFamily;
      color: $themeBackground;
    }
  }
  .btnOk{
    width: 168px;
    height: 35px;
    border: none;
    border-radius: 7px;
    color: #fff;
    font: 500 15px "Poppins";
    cursor: pointer;
    background: #ff0200;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      border: 1px solid #ff0200;
      color: #ff0200;
      background: transparent;
    }
  }
  