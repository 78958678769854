@import "../../styles/variables";

.customInput {
  width: 100%;
  height: 3rem;
  border: none;
  border-radius: $borderRadiusEight;
  background: $inputBackground;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12) inset;
  padding-left: 1.7rem;
  font: 400 15px $fontFamily;
  color: $black;

  @media (max-width: 425px) {
    height: 3rem !important;
    font: 400 12px $fontFamily !important;
    padding-left: 0.7rem !important;
  }

  &::placeholder {
    font: 400 15px $fontFamily;
    color: $textLightGray;

    @media (max-width: 425px) {
      font: 400 12px $fontFamily !important;
    }
  }
}
