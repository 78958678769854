@import "../../styles/variables";

.custombutton{
    width: 100%;
    height: 50px;
    background: $themeBackground;
    border: none;
    border-radius: $borderRadiusTen;
    color: $white;
    font: 500 15px $fontFamily;
    cursor: pointer;

    &:hover{
        background: $white;
        color: $themeBackground;
        border: 1px solid $themeBackground;
    }
}