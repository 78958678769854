/*Custom Css For Antd Table(Payment Table)*/
.custom-table .ant-table-thead {
  height: 39px !important;
}

.custom-table .ant-table-thead th {
  color: white !important;
  padding: 0px 16px !important;
  background-color: #ff0200 !important;
  font: 400 1rem "Poppins";
}

.custom-table .ant-table-thead th:first-child {
  border-top-left-radius: 23px !important;
  border-bottom-left-radius: 23px !important;
  padding-left: 3rem !important;
}
.custom-table .ant-table-thead th:last-child {
  border-top-right-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
}

.custom-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  height: 0rem !important;
}
.custom-table .ant-table-tbody > tr:hover {
  /* background-color: inherit !important; */
  color: inherit;
}
.custom-table .ant-table-tbody > tr > td {
  padding: 16px 16px;
  font: 400 15px "Poppins" !important;
}
.custom-table .ant-table-tbody > tr > td:first-child {
  padding-left: 3rem !important;
}
.custom-table .ant-table-tbody > tr > td:last-child {
  padding-right: 3rem !important;
}

/*Custom Table with five coloumns*/

/*custom-table-inactive Css For Antd Table*/
.custom-table-inactive .ant-table-thead {
  height: 39px !important;
}

.custom-table-inactive .ant-table-thead th {
  color: white !important;
  padding: 0px 16px !important;
  background-color: #ff0200 !important;
  font: 400 1rem "Poppins";
}

.custom-table-inactive .ant-table-thead th:first-child {
  border-top-left-radius: 23px !important;
  border-bottom-left-radius: 23px !important;
  padding-left: 3rem !important;
}
.custom-table-inactive .ant-table-thead th:nth-child(3) {
  border-top-right-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
}

.custom-table-inactive
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  height: 0rem !important;
}
.custom-table-inactive .ant-table-tbody > tr > td {
  font: 400 15px "Poppins" !important;
  padding: 16px 16px;
}
.custom-table-inactive .ant-table-tbody > tr > td:first-child {
  padding-left: 3rem !important;
}
.custom-table-inactive .ant-table-tbody > tr > td:nth-child(3) {
  padding-right: 3rem !important;
}

/*Error Text For CustomInput Fields*/
.error {
  color: #ff0200;
  font: 400 12px "Poppins";
}

/*All Drivers page Tabs' custom styling*/

.custom-tab-wrapper {
  width: 94%;
  margin: 2rem auto;
  /* background-color: bisque; */
}
.custom-tab-wrapper .ant-tabs-nav {
  margin: 0px;
  padding-left: 1.5rem;
}

.custom-tab-wrapper .ant-tabs-nav .ant-tabs-tab {
  background-color: #fff;
  padding: 6px 33px !important;
  border-radius: 14px 14px 0px 0px !important;
  font: 400 18px "Poppins" !important;
}
.custom-tab-wrapper .ant-tabs-nav .ant-tabs-tab:hover {
  color: #ff0200 !important;
}

.custom-tab-wrapper .ant-tabs-tab-active {
  background-color: #ff0200 !important;
  position: relative;
}
/* .custom-tab-wrapper .ant-tabs-tab-active::after {
  content: ""; 
  position: absolute; 
  bottom: -1px; 
  height: 2px; 
  background: linear-gradient(180deg, #ff0200 16.22%, #ffb71500 100%);
  width: 250px; 
  z-index: 500 !important;
}

.custom-tab-wrapper .ant-tabs-tab-active::after {
  right: -245px; 
  border-radius: 1px;
  z-index: 500 !important;
} */

.custom-tab-wrapper .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.custom-tab-wrapper .ant-tabs-content-holder {
  border-radius: 14px;
  background: #fff;
}

.custom-password span input {
  background-color: #f6f6f6 !important;
  font: 400 15px "Poppins";
  color: #000;
}
.custom-password span input::placeholder {
  font: 400 15px "Poppins";
  color: #999898;
}

.searchInputWrapper {
  width: 300px;
  height: 24px;
}

.searchInputWrapper input {
  background: #ff0200;
  color: #fff;
  font: 300 13px "poppins";
}

.searchInputWrapper input::placeholder {
  color: #fff;
  font: 300 13px "poppins";
}

.searchInputWrapper .ant-input-prefix {
  position: relative;
  margin-right: 1.3rem !important;
}

.searchInputWrapper .ant-input-prefix:after {
  content: "";
  position: absolute;
  top: 0;
  right: -12px; /* Adjust the positioning as needed */
  bottom: 0;
  width: 2px;
  height: 20px;
  /* Adjust the width of the divider line */
  background-color: #ccc; /* Adjust the color of the divider line */
}

/*custom-table-inactive Css For Antd Table*/
.custom-table-inactive-four .ant-table-thead {
  height: 39px !important;
}

.custom-table-inactive-four .ant-table-thead th {
  color: white !important;
  padding: 0px 16px !important;
  background-color: #ff0200 !important;
  font: 400 1rem "Poppins";
}

.custom-table-inactive-four .ant-table-thead th:first-child {
  border-top-left-radius: 23px !important;
  border-bottom-left-radius: 23px !important;
  padding-left: 3rem !important;
}
.custom-table-inactive-four .ant-table-thead th:last-child {
  border-top-right-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
}

.custom-table-inactive-four
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  height: 0rem !important;
}
.custom-table-inactive-four .ant-table-tbody > tr > td {
  font: 400 15px "Poppins" !important;
  padding: 16px 16px;
  opacity: 0.5;
}
.custom-table-inactive-four .ant-table-tbody > tr > td:first-child {
  padding-left: 3rem !important;
}
.custom-table-inactive-four .ant-table-tbody > tr > td:last-child {
  padding-right: 3rem !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  width: 19px;
  height: 19px;
  background-color: #ffb715;
  border-color: #fff;
}

.highlighted-row {
  color: #ff0200 !important;
}
.highlighted-row:hover td {
  background-color: #f8ae01 !important;
  color: #fff;
}

.absCenter {
  margin: 50vh 50%;
}

@media (max-width: 425px) {
  .absCenter {
    margin: 50vh 46%;
  }
}


.absCenter p {
  font: 500 18px "Poppins";
}

.swiper-pagination-bullet-active {
  background-color: #ff0200 !important;
  width: 10px !important;
  height: 10px !important;
}

.PhoneInputInput {
  height: 42px;
  border: none;
  border-radius: 8px;
  background: #f6f6f6;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12) inset;
  padding-left: 1.7rem;
  font: 400 15px "Poppins";
  color: #000;
}

.PhoneInputInput::placeholder {
  color: #999898;
}

.PhoneInputInput:disabled {
  background-color: #f2f2f2;
  box-shadow: none;
  color: #999898;
}

.PhoneInputCountry {
  border: none;
  border-radius: 8px;
  background: #f6f6f6 !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12) inset;
  justify-content: center;
  width: 3rem;
}

.ant-input-group .ant-input-group-addon {
  background-color: transparent;
}

.ant-input-group .ant-input {
  height: 2rem !important;
}

/*------------------------------------------------*/

.ant-checkbox-checked .ant-checkbox:hover,
.ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-input:hover + .ant-checkbox-inner {
  background-color: #f8ae01 !important;
  border-color: #f8ae01 !important;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:hover + .ant-checkbox-inner {
  border-color: #d9d9d9 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #ffb715;
}

/*-------------------------------------*/
.table-href-tags {
  text-decoration: none;
  color: initial;
}
.table-href-tags:hover {
  color: #ff0200;
}

.ant-select-selector {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12) inset !important;
  border-radius: 8px !important;
  background: #f6f6f6 !important;
  padding-left: 1.7rem !important;
  color: #999898 !important;
  font: 400 15px "Poppins";
}

.ant-select-selection-item{
  color: #000 !important;
  font: 400 15px "Poppins";
}