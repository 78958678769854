@import "../../styles/variables";

.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #ff0200 0%, #ff0200 100%);
  position: relative;
  overflow: hidden;
  .circleShade {
    position: absolute;
    width: 634.962px;
    height: 634.962px;
    transform: rotate(-94.938deg);
    flex-shrink: 0;
    border-radius: 634.962px;
    background: linear-gradient(
      140deg,
      #ffb715 -29.82%,
      rgba(255, 183, 21, 0) 87.66%
    );
    bottom: -10rem;
    left: -10rem;
    z-index: 100;
  }
  .innerContainer {
    display: flex;
    width: 90%;
    height: 84vh;
    margin-top: 8vh;
    margin-bottom: 8vh;
    border-radius: 2.4rem;
    overflow: hidden;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 200;

    .innerLeft {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 53;
      height: 100%;
      background: linear-gradient(37deg, #ff0200 43.91%, #ffb800 100%);
      .iconTextWrapper {
        margin: 0rem 9%;
        p {
          margin-top: 1rem;
          font: 400 20px $fontFamily;
          color: $white;
        }
      }
    }

    .innerRight {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 47;
      height: 100%;
      background-color: $white;

      .welcomeTextWrapper {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        p {
          &:nth-child(1) {
            font: 500 30px $fontFamily;
            color: $black;
          }
          &:nth-child(2) {
            font: 500 18px $fontFamily;
            color: $black;
          }
        }
      }

      form {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 1.4rem;
        margin-top: 1.8rem;
      }

      a {
        font: 400 12px $fontFamily;
        color: $black;
        margin-top: 0.9rem;

        &:hover{
          color: $themeBackground;
        }
      }
    }
  }
}
