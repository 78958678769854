@import "../../styles/variables";

.mainWrapper{
    width: 94%;
    margin: 1.5rem auto 1.5rem;
    background-color: $white;
    border-radius: $borderRadiusFourteen;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .noDataWrapper {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 3rem auto 3rem;
        padding-bottom: 2rem;
        color: $textLightGray;
        border-bottom: 1px solid $textLightGray;
    
        p {
          font: 400 1rem $fontFamily;
        }
    }
    .dataTableWrapper{
        width: 95%;
        margin: 2% auto 0%;

        .tableWrapperHeader{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
        }
    }
}

.tableEditButtonWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    width: 100%;
  
    // button {
    //   background-color: #ff0200;
    //   justify-content: center;
    //   align-self: center;
    //   border-radius: 4px;
    //   width: 50px;
    //   height: 23px;
    //   margin: 0px;
    //   gap: 2px;
    //   font:500 8px $fontFamily;
  
    //   &:hover{
    //       border: 1px solid $themeBackground;
    //   }
    // }
  }

  .heading{
    font: 500 1.25rem $fontFamily;
  }