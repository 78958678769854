@import "../../styles/variables";

.mainLayoutWrapper {
  height: 100vh;
  overflow: hidden;
  background: $themeBackgroundLight;

  .innerMain {
    display: flex;
    flex-direction: row;

    .sidebarPart {
      width: 270px;
      height: calc(100vh - 5.4rem);
    }

    .content {
      height: calc(100vh - 5.4rem);
      width: calc(100% - 270px);
      overflow-y: auto;

      .layoutFooter {
        width: 100%;
        min-height: 2.5rem;
        background: linear-gradient(270deg, #ffb800 -3.32%, #ff0200 100%);
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: $white;
          font: 400 12px $fontFamily;
        }
      }
    }
  }
}

.dashboardPaymentForm {
  display: flex;

  .invoiceSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
    padding: 2rem;
    border-radius: 10px;
    min-width: 400px;
    background: $white;

    p {
      &:nth-child(1) {
        font: 700 20px $fontFamily;
      }

      &:nth-child(2) {
        font: 500 14px $fontFamily;
        margin-top: 3rem;

        span {
          font-weight: 300;
        }
      }

      &:nth-child(3) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;

        span {
          font-weight: 300;
        }
      }

      &:nth-child(4) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;
        span {
          font-weight: 300;
        }
      }

      &:nth-child(5) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;
        span {
          font-weight: 300;
        }
      }

      &:nth-child(6) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;
        span {
          font-weight: 300;
        }
      }
    }

    .divider {
      width: 100%;
      border-top: 1px solid gray;
      margin-top: 9rem;
    }

    .totalPayable {
      font: 500 14px $fontFamily;
      margin-top: 0.6rem;
      align-self: flex-end;
      span {
        font-weight: 300;
      }
    }
  }
}
