@import "styles/variables";

.modalText {
    font: 500 25px $fontFamily;
    align-self: center;
  
    span {
      font: 700 25px $fontFamily;
      color: $themeBackground;
    }
  }

  .inputFieldsWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    .inputAndErrorWrapper{
        display: flex;
        flex-direction: column;
        width: 48.5%;
    }
}