@import "../../styles/variables";

.companyTableWrapper {
  width: 94%;
  margin: 1.5rem auto 1.5rem;
  padding: 1.1rem 1.9rem 0.2rem 1.9rem;
  background-color: $white;
  border-radius: $borderRadiusFourteen;
  position: relative;
}

.noDataWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem auto 1rem;
  padding-bottom: 2rem;
  color: $textLightGray;
  border-bottom: 1px solid $textLightGray;

  p {
    font: 400 1rem $fontFamily;
  }
}

.titleAndBarWrapper {
  display: flex;
  justify-content: space-between;

  p {
    font: 500 1.25rem $fontFamily;
  }
}
