@import "../../styles/variables";

.InActiveInput {
  width: 100%;
  height: 3.1rem;
  border: none;
  border-radius: $borderRadiusEight;
  background: #f2f2f2;
  padding-left: 1.7rem;
  font: 400 15px $fontFamily;
  color: $textLightGray;

  &::placeholder {
    font: 400 15px $fontFamily;
    color: $textLightGray;
  }

  &:focus{
    outline: none;
  }

}
