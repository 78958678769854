@import "../../styles/variables";

.textArea {
  width: 100%;
  height: 5.4rem;
  border: none;
  border-radius: $borderRadiusTen;
  background: #f2f3ff;
  padding-left: 1.7rem;
  padding-top: 0.8rem;
  font: 400 15px $fontFamily;
  color: $black;
  resize: none;

  @media (max-width: 425px) {
    font: 400 12px $fontFamily !important;
    padding-left: 0.7rem !important;
    padding-top: 0.7rem;
  }

  &::placeholder {
    font: 400 15px $fontFamily;
    color: $textLightGray;

    @media (max-width: 425px) {
      font: 400 12px $fontFamily !important;
    }
  }
}
