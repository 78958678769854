@import "../../styles/variables";

.profileWrapper {
  width: 94%;
  margin: 1.5rem auto 1.5rem;
  background-color: #fff;
  border-radius: $borderRadiusFourteen;
  position: relative;
  padding: 2rem 2rem 2.4rem 2rem;

  .profileHeader {
    display: flex;
    align-items: center;
    gap: 1.1rem;

    .profileAndEditWrapper {
      width: 4.25rem;
      height: 4.25rem;
      background-color: $black;
      border-radius: $borderRadiusTen;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .edit {
        width: 100%;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0px;
        background-color: $black;
        cursor: pointer;
      }
    }

    p {
      font: 500 1.25rem $fontFamily;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    .inputFieldsWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .inputAndErrorWrapper {
        display: flex;
        flex-direction: column;
        width: 49%;

        label {
          font: 600 1.2rem $fontFamily;
          color: $textDarkGray;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
