$themeBackground: #FF0200;
$themeYellow: #FFB715;
$themeBackgroundLight: #F5F4F6;
$white: #fff;
$black: #000;
$darkGray: #8F8F8F;
$textLightGray: #999898;
$textDarkGray: #636363;
$textDarkestGray: #7A7A7A;
$textDarkestGrayT: #858585;
$inputBackground: #F6F6F6;
$tileBackground: #F4F6FA;
$blockBtnBackground: #FF9000;
//fonts
$fontFamily: "Poppins";

//Border
$borderRadiusSeven: 7px;
$borderRadiusEight: 8px;
$borderRadiusTen: 10px;
$borderRadiusFourteen: 14px;
$borderRadiusTwelve:12px;
$borderRadiusNine: 9px;