@import "../../styles/variables";

.header {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 8.5rem;
  z-index: 200;
  position: fixed;
  top: 0;

  // button{
  //   @media (max-width: 425px) {
  //     display: none;
  //   }
  // }

  @media (max-width: 425px) {
    padding: 0rem 1.5rem;
  }
  .headerLeftWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10.25rem;

    .headerNavWrapper {
      display: flex;
      gap: 2.5rem;
      @media (max-width: 425px) {
        display: none;
      }
      p {
        font: 500 14px $fontFamily;
        color: $white;
        cursor: pointer;
        text-underline-offset: 5px;

        &:hover {
          color: $themeYellow;
        }
      }
    }
  }

  .navMenuWapper {
    position: relative;
    display: none;

    @media (max-width: 425px) {
      display: block;
    }

    .menu {
      position: absolute;
      top: 3rem;
      right: -1rem;
      border-radius: 0.5rem;
      width: 160px;
      background: linear-gradient(162deg, #ff5117 19.11%, #ff4d08 87.77%);
      box-shadow: 4.526px 4.526px 4.526px 4.526px rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      .menuOptions {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 400 14px $fontFamily;
        border-bottom: 1px solid $white;
        color: $white;
      }
      .menuOptions:last-child {
        border-bottom: none;
      }

      .menuLoginBtn {
        width: 100%;
        display: flex;
        justify-content: center;
        border-top: 1px solid $white;

        button {
          @media (max-width: 425px) {
            display: block;
          }
        }
      }
    }
  }
}

.profileSectionWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  position: relative;
  height: 100%;

  .profileImgWrapper {
    width: 40px;
    height: 40px;
    background: black;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 3.1rem;
    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    font: 700 1rem $fontFamily;
    color: $white;
  }
  .menuDropdown {
    position: absolute;
    right: 0px;
    top: 5.4rem;
    display: none;
    width: 100%;
    background: linear-gradient(270deg, #ffb800 -3.32%, #ff9100 100%);
    z-index: 200;
    border-radius: 7px;
    overflow: hidden;
    // border-bottom-right-radius: 7px;

    .dropdownMenuOptions {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font: 500 1rem $fontFamily;

      &:hover {
        background-color: $themeBackground;
        color: $white;
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    .dropdownMenuOptions:nth-child(even) {
      border-top: 1px solid $white;
    }
  }

  &:hover {
    .menuDropdown {
      display: block;
    }
  }
}
