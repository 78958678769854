@import "styles/variables";

.modalText {
    font: 500 25px $fontFamily;
    align-self: center;
  
    span {
      font: 700 25px $fontFamily;
      color: $themeBackground;
    }
  }

  .inputFieldsWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    .inputAndErrorWrapper{
        display: flex;
        flex-direction: column;
        width: 48.5%;
    }
}

.companyInfoWrapper{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-self: flex-start;

  p{
    font: 700 12px $fontFamily;

    span{
      font-weight: 400;
    }
  }
}