@import "../../styles/variables";

.customPasswordInput {
  width: 100%;
  height: 3rem;
  border: none;
  border-radius: $borderRadiusEight;
  background: $inputBackground;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12) inset;
  padding-left: 1.7rem;
  font: 400 15px $fontFamily;
  color: $black;

  &::placeholder {
    font: 400 15px $fontFamily;
    color: $textLightGray;
  }
}