@import "../../styles/variables";

.privacyPolicyWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  padding: 3.25rem 8.5rem 4.8rem 8.5rem;
  position: relative;
  margin-top: 90px;

  @media (max-width: 425px) {
    padding: 5%;
  }

  .bgImage {
    position: absolute;
    left: 0px;
    top: 5px;
  }

  .privacyPolicyHeading {
    font: 600 2.2rem $fontFamily;
    align-self: flex-start;

    @media (max-width: 768px) {
      font: 600 3rem $fontFamily;
    }
    @media (max-width: 425px) {
      font: 600 1.7rem $fontFamily;
    }
  }

  .privacyPolicyContent {
    margin: 0 auto;

    @media (max-width: 425px) {
      margin: 0;
    }
    p {
      font: 400 15px $fontFamily;
      text-align: justify;
      margin-bottom: 20px;

      @media (max-width: 425px) {
        font: 400 12px $fontFamily;
      }
    }

    .privacyPolicyHeading {
      font: 600 2.2rem $fontFamily;
      align-self: flex-start;

      @media (max-width: 768px) {
        font: 600 3rem $fontFamily;
      }
      @media (max-width: 425px) {
        font: 600 2rem $fontFamily;
      }
    }
  }
}
