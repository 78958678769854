@import "../../styles/variables";

.dragAndDropWrapper {
  width: 100%;
  height: 368px;
  background-color: $white;
  border-radius: $borderRadiusFourteen;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .dropDownContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.4rem;

    .dropDownContent {
      width: 20.8rem;
      height: 8.3rem;
      border-radius: 22px;
      border: 1px dashed #7d7d7d;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        &:nth-child(2) {
          font: 500 14px $fontFamily;
          margin-top: 17px;
        }
        &:nth-child(3) {
          font: 500 11px $fontFamily;
          color: $themeBackground;
          margin-top: 6px;
        }
      }
    }

    button {
      width: 168px;
      height: 35px;
      border: none;
      border-radius: 7px;
      color: #fff;
      font: 500 15px "Poppins";
      cursor: pointer;
      background: #ff0200;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

      &:hover {
        border: 1px solid #ff0200;
        color: #ff0200;
        background: transparent;
      }

      &:disabled{
        color: #fff;
        background: #ff0200;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.addDriverFormWrapper {
  width: 100%;
  background-color: $white;
  border-radius: $borderRadiusFourteen;
  padding: 2.3rem;

  p {
    font: 500 1.25rem $fontFamily;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 2.9rem;
    .inputFieldsWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .inputAndErrorWrapper {
        display: flex;
        flex-direction: column;
        width: 49%;
      }
    }
  }
}

.allDriversWrapper {
  width: 95%;
  // min-height: 399px;
  margin: -0.5rem auto 2.5rem;
  background-color: $white;
  border-radius: $borderRadiusFourteen;
  padding-top: 1.6rem;
  position: relative;
  box-sizing: border-box;

  .allDriversInnerWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    padding: 0px 30px;
    box-sizing: border-box;
    justify-content: center;

    .driverInfoWrapper {
      width: 9.6rem;
      height: 5.8rem;
      border-radius: $borderRadiusNine;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      .info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 3px;
        position: relative;
        padding-left: 1rem;
        box-sizing: border-box;

        &:hover {
          background-color: $themeBackground;
          p {
            &:nth-child(1) {
              color: $white;
            }
            &:nth-child(2) {
              color: $white;
            }
            &:nth-child(3) {
              color: $white;
            }
          }
        }
        p {
          &:nth-child(1) {
            font: 600 15px $fontFamily;
          }
          &:nth-child(2) {
            font: 400 13px $fontFamily;
            color: $textDarkestGray;
          }
          &:nth-child(3) {
            font: 400 13px $fontFamily;
            color: $textDarkestGray;
          }
        }

        .moreIconWrapper {
          position: absolute;
          right: 0px;
          top: 30px;
          cursor: pointer;
        }
      }
      .moreOptions {
        display: flex;
        height: 100%;

        p {
          font: 600 10px $fontFamily;
          color: $white;
        }
        .blockWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          gap: 4px;
          background-color: $themeBackground;
        }
        .editWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          gap: 10px;
          background-color: $themeYellow;
        }
      }
    }
  }

  .finishButtonWrapper {
    width: 95%;
    margin: 0px auto;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
}

.modalText {
  font: 500 25px $fontFamily;
  align-self: center;

  span {
    font: 700 25px $fontFamily;
    color: $themeBackground;
  }
}

.tableEditButtonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.dashboardPaymentForm {
  display: flex;

  .invoiceSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
    padding: 2rem;
    border-radius: 10px;
    min-width: 400px;
    background: $white;

    p {
      &:nth-child(1) {
        font: 700 20px $fontFamily;
      }

      &:nth-child(2) {
        font: 500 14px $fontFamily;
        margin-top: 3rem;

        span {
          font-weight: 300;
        }
      }

      &:nth-child(3) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;

        span {
          font-weight: 300;
        }
      }

      &:nth-child(4) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;
        span {
          font-weight: 300;
        }
      }

      &:nth-child(5) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;
        span {
          font-weight: 300;
        }
      }

      &:nth-child(6) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;
        span {
          font-weight: 300;
        }
      }

      &:nth-child(7) {
        font: 500 20px $fontFamily;
        margin-top: 0.8rem;
        span {
          font-weight: 300;
        }
      }

      &:nth-child(8) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;
        span {
          font-weight: 300;
        }
      }
      &:nth-child(9) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;
        span {
          font-weight: 300;
        }
      }
      &:nth-child(10) {
        font: 500 14px $fontFamily;
        margin-top: 0.3rem;
        span {
          font-weight: 300;
        }
      }
    }

    .divider {
      width: 100%;
      border-top: 1px solid gray;
      margin-top: 9rem;
    }

    .totalPayable {
      font: 500 14px $fontFamily;
      margin-top: 0.6rem;
      align-self: flex-end;
      span {
        font-weight: 300;
      }
    }
  }
}
