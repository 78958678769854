@import "../../styles/variables";
.headerWrapper {
  display: flex;
  width: 100%;
  height: 5.4rem;
  background: linear-gradient(270deg, #ffb800 -3.32%, #ff0200 100%);

  .iconWrapper {
    width: 270px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .searchAndProfleWrapper {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 270px);
    height: 100%;
    padding-left: 2.6rem;

    .searchInputWrapper {
      width: 300px;
      height: 24px;
      input {
        background: $themeBackground;
        color: $white;
        font: 300 13px $fontFamily;
        &::placeholder {
          color: $white;
          font: 300 13px $fontFamily;
        }
      }
    }

    .profileSectionWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      position: relative;

      .profileImgWrapper {
        width: 40px;
        height: 40px;
        background: black;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 3.1rem;
        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        font: 700 1rem $fontFamily;
      }
      .menuDropdown {
        position: absolute;
        right: 0px;
        top: 5.4rem;
        display: none;
        width: 100%;
        background: linear-gradient(270deg, #ffb800 -3.32%, #ff9100 100%);
        z-index: 200;
        border-bottom-left-radius: 7px;
        overflow: hidden;
        // border-bottom-right-radius: 7px;

        .dropdownMenuOptions {
          height: 50px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font: 500 1rem $fontFamily;

          &:hover{
            background-color: $themeBackground;
            color: $white;
          }
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        .dropdownMenuOptions:nth-child(even) {
          border-top: 1px solid $white;
        }
      }

      &:hover {
        .menuDropdown {
          display: block;
        }
      }
    }
  }
}

.planWrapper {
  display: flex;
  align-items: center;
  gap: 3rem;

  .subsPlanWrapper {
    display: flex;
    gap: 1rem;
    p {
      font: 500 20px $fontFamily;
      color: $white;
    }
    .planWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 78px;
      height: 33px;
      color: $themeBackground;
      background-color: $white;
      border-radius: 10px;
      font: 500 1rem $fontFamily;
    }
  }

  .remDaysWrapper {
    width: 198px;
    height: 42px;
    border-radius: 12px;
    background-color: $themeBackground;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15),
      2px 2px 4px 0px rgba(0, 0, 0, 0.15) inset;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: $white;
      font: 500 1rem $fontFamily;

      span {
        font-weight: 700;
      }
    }
  }
}
