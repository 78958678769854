@import "../../styles/variables";

.IconButton{
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    border-radius: $borderRadiusTen;
    color: $white;
    font: 500 15px $fontFamily;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    &:hover{
        background: $white; 
        color: $themeBackground;
    }

}