@import "../../styles/variables";

.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .headerContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: url("../../../public/Images/LandingPageHeaderbgImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .headerpatternImage {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 42.5%;
      z-index: 100;
      @media (max-width: 425px) {
        display: none;
      }
    }

    .headerSpiralBgImg {
      position: absolute;
      right: 37%;
      bottom: 10%;
      width: 213px;
      height: 213px;
      z-index: 50;
    }

    .innerHeaderSection {
      display: flex;
      margin-top: 90px;

      @media (max-width: 425px) {
        flex-direction: column;
      }

      .introSection {
        width: 50%;
        // background-color: pink;
        padding: 5.7rem 0rem 0rem 8.5rem;
        margin-bottom: 20.5rem;

        @media (max-width: 425px) {
          width: 100%;
          padding: 1rem 1rem 0rem 1rem;
          margin-bottom: 2rem;
        }

        .introHeadingWrapper {
          p {
            font: 700 1.75rem $fontFamily;
            color: $white;
            @media (max-width: 425px) {
              font: 700 1rem $fontFamily;
            }
          }
        }

        .introSubHeadingWrapper {
          // padding-right: 5rem;
          margin-top: 1.2rem;
          @media (max-width: 425px) {
            padding-right: 2rem;
            margin-top: 1.2rem;
          }
          p {
            font: 400 1rem $fontFamily;
            color: $white;

            span {
              font: 700 1rem $fontFamily;
            }

            @media (max-width: 425px) {
              font: 400 0.8rem $fontFamily;
            }
          }
        }

        .headerBtn {
          width: 176px;
          height: 43px;
          margin-top: 2.4rem;
          margin-bottom: 14.4rem;
          background-color: $white;
          border: none;
          border-radius: 47px;
          color: $themeBackground;
          box-shadow: 0px 4.526px 4.526px 0px rgba(0, 0, 0, 0.25);
          font: 400 14px $fontFamily;
          cursor: pointer;

          @media (max-width: 425px) {
            margin-top: 1rem;
            margin-bottom: 2.4rem;
          }

          &:hover {
            background-color: $themeBackground;
            color: $white;
          }
        }

        .headerButtonsWrapper {
          display: flex;
          gap: 1.25rem;
          margin-top: 2.4rem;
        }

        .socialWrapper {
          display: flex;
          margin-top: 6rem;
          .phoneWrapper {
            display: flex;
            gap: 11px;
            padding-right: 1rem;
            border-right: 1px solid #ccc;
            p {
              font: 600 1.1rem $fontFamily;
            }
          }
          .socialIconsWrapper {
            display: flex;
            gap: 1rem;
            padding-left: 1rem;
          }
        }
      }

      .rightImageSection {
        display: none;

        @media (max-width: 425px) {
          width: 100%;
          display: block;
          height: 305px;
        }
        .headerBackgroundImage {
          // position: absolute;
          width: 505px;
          height: 505px;
          // right: 10%;
          // top: 10.5%;
          @media (max-width: 425px) {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .swipperWrapper {
    width: 100%;
    height: 449px;
    margin: 3rem 0rem;
    background: rgba(255, 2, 0, 0.05);

    .swipperHeading {
      font: 400 2.25rem $fontFamily;
      text-align: center;
      margin-top: 3.7rem;
    }

    .swipperSlideWrapper {
      height: 280px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      img {
        width: 190px;
        height: 110px;
      }
    }
  }

  .aboutUsWrapper {
    display: flex;
    margin-bottom: 5rem;
    margin-top: 2rem;
    position: relative;

    @media (max-width: 425px) {
      margin-bottom: 2rem;
      flex-direction: column;
    }

    .aboutUsBgleft {
      position: absolute;
    }
    .aboutUsBgRight {
      position: absolute;
      right: 46%;
      bottom: 51px;
    }

    .aboutUsLeftWrapper {
      width: 50%;
      height: 33rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 8.5rem;
      padding-right: 6rem;
      @media (max-width: 425px) {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      p {
        &:nth-child(1) {
          font: 400 2.25rem $fontFamily;
          margin-bottom: 1rem;
          @media (max-width: 425px) {
            font: 700 1.25rem $fontFamily;
          }
        }
        &:nth-child(2) {
          font: 400 0.9rem $fontFamily;
          color: $textDarkestGray;
          @media (max-width: 425px) {
            font: 400 0.8rem $fontFamily;
          }
        }
      }
    }
    .aboutUsRightWrapper {
      width: 50%;
      display: flex;
      align-items: center;
      @media (max-width: 425px) {
        width: 100%;
        justify-content: center;
      }

      img {
        margin-top: 4rem;
        width: 85%;
        height: 83%;
        @media (max-width: 425px) {
          margin-top: 1rem;
          width: 90%;
        }
      }
    }
  }

  .taxiCompanySection {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(162deg, #ff0200 19.11%, #ffb715 87.77%);
    // margin-bottom: 2.9rem;

    .conAppSecHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2.6rem 18rem 2.6rem 18rem;

      @media (max-width: 425px) {
        padding: 1rem 1rem 1rem 1rem;
      }

      p {
        color: $white;

        &:nth-child(1) {
          font: 600 2.25rem $fontFamily;
          @media (max-width: 425px) {
            font: 600 1.25rem $fontFamily;
          }
        }

        &:nth-child(2) {
          font: 400 1rem $fontFamily;
          text-align: center;
          span {
            font-weight: 700;
          }
          @media (max-width: 425px) {
            font: 400 0.7rem $fontFamily;
          }
        }
      }
    }

    .conAppSubFirst {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 7rem;
      padding-left: 11rem;
      padding-right: 7rem;

      @media (max-width: 425px) {
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
        gap: 1rem;
      }

      .contentWrapper {
        display: flex;
        gap: 1rem;
        flex-direction: column;

        .noAndContent {
          display: flex;
          gap: 9px;
          align-items: center;
          .numberWrapper {
            width: 53px;
            height: 53px;
            background-color: $white;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              color: $themeBackground;
              font: 600 2.25rem $fontFamily;
            }
          }
          .contentDes {
            font: 400 1.1rem $fontFamily;
            color: $white;
            @media (max-width: 425px) {
              font: 400 0.8rem $fontFamily;
            }
          }
        }

        .contentDes {
          font: 400 1.1rem $fontFamily;
          color: $white;

          @media (max-width: 425px) {
            font: 400 0.8rem $fontFamily;
          }
        }
      }

      img {
        @media (max-width: 425px) {
          width: 90%;
          margin-top: 2rem;
        }
      }
    }

    .conAppSubSecond {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 7rem;
      padding-left: 11rem;
      padding-right: 7rem;

      @media (max-width: 425px) {
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
        gap: 1rem;
      }

      .contentWrapper {
        display: flex;
        gap: 1rem;
        flex-direction: column;

        .noAndContent {
          display: flex;
          gap: 9px;
          align-items: center;
          .numberWrapper {
            width: 53px;
            height: 53px;
            background-color: $white;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              color: $themeBackground;
              font: 600 2.25rem $fontFamily;
            }
          }
          .contentDes {
            font: 400 1.1rem $fontFamily;
            color: $white;
            @media (max-width: 425px) {
              font: 400 0.8rem $fontFamily;
            }
          }
        }

        .contentDes {
          font: 400 1.1rem $fontFamily;
          color: $white;
          @media (max-width: 425px) {
            font: 400 0.8rem $fontFamily;
          }
        }
      }

      img {
        @media (max-width: 425px) {
          width: 90%;
          margin-top: 2rem;
        }
      }

      .desktopImage {
        @media (max-width: 425px) {
          display: none;
        }
      }

      .mobileImage {
        display: none;
        @media (max-width: 425px) {
          display: block;
          margin-top: 2rem;
        }
      }
    }
  }

  .interfaceSection {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-bottom: 5.5rem;

    .firstSection {
      width: 572px;
      min-height: 556.5px;
      border-radius: 16px;
      padding: 2.8rem 1rem 0rem 2rem;
      background-color: $themeBackground;

      @media (max-width: 425px) {
        width: 286px;
        padding: 1rem 1rem 0rem 1rem;
      }

      .headingWrapper {
        padding-right: 5rem;
        @media (max-width: 425px) {
          padding-right: 0rem;
        }
        p {
          font: 700 26px $fontFamily;
          color: $white;
          @media (max-width: 425px) {
            font: 700 1rem $fontFamily;
          }
        }
      }
      .firstDes {
        margin-top: 0.5rem;
        font: 400 1rem $fontFamily;
        line-height: 28px;
        color: $white;
        @media (max-width: 425px) {
          font: 400 0.7rem $fontFamily;
        }
      }
      .bottomWrapper {
        width: 100%;
        display: flex;
        @media (max-width: 425px) {
          flex-direction: column;
        }
        .bottomLeft {
          display: flex;
          flex-direction: column;
          padding-right: 5px;

          p {
            font: 400 1rem $fontFamily;
            line-height: 28px;
            color: $white;
            @media (max-width: 425px) {
              font: 400 0.7rem $fontFamily;
            }
          }

          .firstSectionBtn {
            width: 150px;
            height: 50px;
            border-radius: 12px;
            border: none;
            background-color: $themeYellow;
            font: 500 1rem $fontFamily;
            color: $white;
            cursor: pointer;
            margin-top: 3.5rem;
          }
          .firstSectionBtn:hover {
            color: $themeBackground;
            border: 1px solid $themeBackground;
            background-color: $white;
          }
        }
      }
    }

    .secondSection {
      width: 286px;
      min-height: 556.5px;
      border-radius: 16px;
      background-color: $themeYellow;

      img {
      }

      .secondSectionHeading {
        margin-top: 1.4rem;
        margin-left: 20px;
        color: $white;
        font: 700 22px $fontFamily;
      }

      .secondSectionSubHeading {
        margin: 0.4rem 10px 2rem 20px;
        color: $white;
        font: 400 1rem $fontFamily;
      }
    }

    .thirdSection {
      width: 286px;
      min-height: 556.5px;
      border-radius: 16px;
      background-color: $themeBackground;
      overflow: hidden;
      position: relative;

      .secondSectionHeading {
        margin-top: 1.4rem;
        margin-left: 2rem;
        color: $white;
        font: 700 24px $fontFamily;
      }

      .secondSectionSubHeading {
        margin: 0.4rem 2rem 2.3rem 2rem;
        color: $white;
        font: 400 1rem $fontFamily;
      }
      img {
        position: absolute;
        bottom: 0px;
      }
    }
  }

  .featuresWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 7rem;
    gap: 3rem;
    margin-bottom: 5.8rem;
    margin-top: 3.7rem;

    .featuresHeader {
      max-width: 840px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        &:nth-child(1) {
          font: 400 2.25rem $fontFamily;
        }

        &:nth-child(2) {
          font: 400 1.1rem $fontFamily;
          color: $textDarkestGray;
          text-align: center;
        }
      }
    }

    .tilesWrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;

      .tile {
        width: 392px;
        height: 160px;
        background-color: $tileBackground;
        border-radius: $borderRadiusNine;
        display: flex;
        align-items: center;
        padding-left: 1.3rem;
        padding-right: 10px;
        gap: 1.3rem;

        .tileTitleAndSubWrapper {
          display: flex;
          flex-direction: column;
          p {
            &:nth-child(1) {
              font: 700 1.25rem $fontFamily;
            }
            &:nth-child(2) {
              font: 400 12px $fontFamily;
              color: $textDarkestGray;
            }
          }
        }
      }
    }
  }

  .whyChooseUs {
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 183, 21, 0.89) 0%,
      rgba(255, 2, 0, 0.65) 54.5%,
      rgba(0, 0, 0, 0.65) 99.81%
    );
    // margin-bottom: 2.9rem;
    background-image: url("../../../public/Images/whyChoseUsBgImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media (max-width: 425px) {
      display: none;
    }
    .whyChooseUsHeader {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 1.9rem 15rem 1.9rem 15rem;

      @media (max-width: 425px) {
        padding: 1.9rem 1.9rem 1.9rem 1.9rem;
      }
      p {
        &:nth-child(1) {
          font: 600 2.25rem $fontFamily;
          color: $white;
          @media (max-width: 425px) {
            font: 600 1.25rem $fontFamily;
          }
        }
        &:nth-child(2) {
          font: 400 1rem $fontFamily;
          color: $white;
          text-align: center;
          @media (max-width: 425px) {
            font: 400 0.8rem $fontFamily;
          }
        }
      }
    }

    .whyChooseUsObjs {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .whyChooseUsObjLeft {
        position: relative;
        height: 350px;
        min-width: 324px;

        @media (max-width: 425px) {
          min-width: 124px;
        }

        p {
          font: 500 18px $fontFamily;
          color: $white;

          @media (max-width: 425px) {
            font: 500 10px $fontFamily;
          }

          &:nth-child(1) {
            position: absolute;
            top: 0rem;
            right: -2rem;
          }
          &:nth-child(2) {
            position: absolute;
            top: 44%;
          }
          &:nth-child(3) {
            position: absolute;
            bottom: 0;
            right: -3rem;
          }
        }
      }
      .whyChooseUsObjMiddle {
        width: 454px;
        height: 454px;
        img {
          width: 100%;
        }
      }
      .whyChooseUsObjRight {
        position: relative;
        height: 350px;
        min-width: 345px;
        @media (max-width: 425px) {
          min-width: 124px;
        }

        p {
          font: 500 18px $fontFamily;
          color: $white;
          @media (max-width: 425px) {
            font: 500 10px $fontFamily;
          }

          &:nth-child(1) {
            position: absolute;
            top: 0rem;
            left: -3rem;
          }
          &:nth-child(2) {
            position: absolute;
            top: 44%;
            left: 0.6rem;
          }
          &:nth-child(3) {
            position: absolute;
            bottom: 0;
            left: -3rem;
          }
        }
      }
    }
    .lastObj {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 11px;
      margin-bottom: 62px;
      p {
        font: 500 18px $fontFamily;
        color: $white;
      }
    }
  }

  .getStartedSection {
    width: 100%;
    background-color: $themeBackground;
    padding-top: 110px;
    padding-left: 131px;
    padding-bottom: 133px;
    margin-bottom: 2.9rem;
    background: linear-gradient(
      90deg,
      #ff0200 38.83%,
      rgba(255, 183, 21, 0.18) 65%
    );
    background-image: url("../../../public/Images/getStartedBgImage.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media (max-width: 425px) {
      padding-top: 2rem;
      padding-left: 2rem;
      padding-bottom: 2rem;
    }

    .getStartedContentWrapper {
      width: 667px;
      display: flex;
      flex-direction: column;
      gap: 2.1rem;

      @media (max-width: 425px) {
        width: 90%;
      }

      p {
        color: $white;
        &:nth-child(1) {
          font: 600 2.25rem $fontFamily;

          @media (max-width: 425px) {
            font: 600 1.25rem $fontFamily;
          }
        }
        &:nth-child(2) {
          font: 500 1.25rem $fontFamily;

          @media (max-width: 425px) {
            font: 500 1rem $fontFamily;
          }
        }
      }

      .getStartedBtn {
        width: 224px;
        height: 65px;
        background-color: $white;
        color: $themeBackground;
        border-radius: 47px;
        border: none;
        cursor: pointer;
        font: 500 1.37rem $fontFamily;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        @media (max-width: 425px) {
          width: 200px;
          font: 500 1rem $fontFamily;
        }

        &:hover {
          background-color: $themeBackground;
          color: $white;
          border: 2px solid $white;
        }
      }
    }
  }

  .pricingSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pricingSectionHeader {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0rem 33%;

      @media (max-width: 425px) {
        padding: 0rem 1rem;
      }

      p {
        text-align: center;
        &:nth-child(1) {
          font: 600 2.25rem $fontFamily;
          @media (max-width: 425px) {
            font: 700 1.25rem $fontFamily;
          }
        }

        &:nth-child(2) {
          font: 400 1.125rem $fontFamily;
          @media (max-width: 425px) {
            font: 400 0.8rem $fontFamily;
          }
        }
      }
    }

    .subsPackagesWrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      gap: 3rem;
      margin: 2.5rem 0rem 6.25rem 0rem;

      .subsPackageFirst {
        width: 367px;
        height: 685.64px;
        border-radius: 32px;
        background: #fff;
        box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid transparent;

        @media (max-width: 425px) {
          width: 286px;
          height: 600px;
        }

        .subsHeading {
          font: 600 2.25rem $fontFamily;
          margin-top: 2.8rem;
        }
        .subsPrice {
          display: flex;
          justify-content: flex-start;
          p {
            &:nth-child(1) {
              font: 700 7.9rem $fontFamily;
              line-height: 148.806px;
            }
            &:nth-child(2) {
              font: 500 1.25rem $fontFamily;
              color: $themeBackground;
              margin-top: 6rem;
            }
          }
        }

        .pricingSectionFeatures {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          margin-left: 2rem;
          margin-top: 3rem;
          @media (max-width: 425px) {
            margin-top: 1rem;
          }

          .singleFeaturesWrapper {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            p {
              font: 400 1.44rem $fontFamily;
              @media (max-width: 425px) {
                font: 400 1rem $fontFamily;
              }
            }
          }
        }

        .infoWrapper {
          padding: 0rem 3.1rem;
          margin-bottom: 3.9rem;
          display: flex;
          gap: 10px;
          p {
            font: 400 20px $fontFamily;
            margin-top: 1.5rem;
          }
        }

        .subsBtn {
          height: 66px;
          padding: 0px 45.5px 0px 44.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 47px;
          background: linear-gradient(180deg, #ff0200 0%, #ffb715 100%);
          color: $white;
          border: none;
          font: 500 1.4rem $fontFamily;
          margin-top: 6rem;
          cursor: pointer;

          @media (max-width: 425px) {
            padding: 0px 35.5px 0px 34.5px;
            font: 500 1rem $fontFamily;
          }
          &:hover {
            border: 1px solid $themeBackground;
            background: $white;
            color: $themeBackground;
          }
        }
        &:hover {
          border: 1px solid $themeBackground;
        }
      }
      .subsPackageSecond {
        width: 367px;
        height: 685.64px;
        border-radius: 32px;
        background: #fff;
        box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid transparent;

        @media (max-width: 425px) {
          width: 286px;
          height: 600px;
        }

        .subsHeading {
          font: 600 2.25rem $fontFamily;
          margin-top: 2.8rem;
        }
        .subsPrice {
          display: flex;
          justify-content: flex-start;
          p {
            &:nth-child(1) {
              font: 500 3.3rem $fontFamily;
              margin-top: 1rem;
            }
            &:nth-child(2) {
              font: 700 7.9rem $fontFamily;
              line-height: 148.806px;
            }
          }

          .eachDriver {
            display: flex;
            flex-direction: column;
            p {
              &:nth-child(1) {
                font: 500 1.25rem $fontFamily;
                color: $themeBackground;
                margin-top: 4.1rem;
              }
              &:nth-child(2) {
                font: 500 1.25rem $fontFamily;
                color: $themeBackground;
              }
            }
          }
        }

        .pricingSectionFeatures {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          margin-top: 3rem;
          @media (max-width: 425px) {
            margin-top: 1rem;
          }
          .singleFeaturesWrapper {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            p {
              font: 400 1.44rem $fontFamily;
              @media (max-width: 425px) {
                font: 400 1rem $fontFamily;
              }
            }
          }
        }

        .infoWrapper {
          padding: 0rem 3.1rem;
          margin-bottom: 3.9rem;
          display: flex;
          gap: 10px;
          p {
            font: 400 20px $fontFamily;
            margin-top: 1.5rem;
          }
        }

        .subsBtn {
          height: 66px;
          padding: 0px 45.5px 0px 44.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 47px;
          background: linear-gradient(180deg, #ff0200 0%, #ffb715 100%);
          color: $white;
          border: none;
          font: 500 1.4rem $fontFamily;
          margin-top: 8rem;
          cursor: pointer;

          @media (max-width: 425px) {
            padding: 0px 35.5px 0px 34.5px;
            margin-top: 7.5rem;
            font: 500 1rem $fontFamily;
          }
          &:hover {
            border: 1px solid $themeBackground;
            background: $white;
            color: $themeBackground;
          }
        }
        &:hover {
          border: 1px solid $themeBackground;
        }
      }
    }
  }

  .contactUsWrapper {
    width: 100%;
    height: 652px;
    background-image: url("../../../public/Images/contactUsBannerImage.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: flex-start;
    @media (max-width: 425px) {
      justify-content: center;
      align-items: center;
    }

    .contactUsFormWrapper {
      width: 611px;
      background: $white;
      position: absolute;
      margin-left: 7.6rem;
      margin-top: 3.1rem;
      border-radius: 15px;
      padding: 0rem 4.5rem;

      @media (max-width: 425px) {
        width: 300px;
        margin-left: 0rem;
        margin-top: 0.8rem;
        padding: 0rem 1rem;
      }

      p {
        &:nth-child(1) {
          font: 600 2.25rem $fontFamily;
          margin-top: 2rem;
          @media (max-width: 425px) {
            font: 600 1.25rem $fontFamily;
            margin-top: 1rem;
          }
        }
        &:nth-child(2) {
          font: 400 1.1rem $fontFamily;
          color: $textDarkestGray;
          margin-top: 1rem;
          @media (max-width: 425px) {
            font: 400 0.8rem $fontFamily;
          }
        }
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2.3rem;
        margin-top: 2.3rem;
        width: 100%;
      }
    }
  }
  .subscribeAndInfoWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 8rem;
    position: relative;

    .subscribeAndInfoBgImg {
      position: absolute;
      bottom: 0.5rem;
      right: 2.5rem;
    }

    .subscribeInnerWrapper {
      border-radius: 129px 20px 20px 20px;
      background-color: #ffe5e5;
      padding: 4.9rem 9.5rem 4.9rem 9.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      .subscribeInnerLefttBgImg {
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .subscribeInnerRightBgImg {
        position: absolute;
        top: 0;
        right: 0;
      }
      .roundIconWrapper {
        position: absolute;
        top: -15px;
        right: -14px;
      }
      p {
        font: 600 2rem $fontFamily;
        text-align: center;
      }
      .sendEmailWrapper {
        display: flex;
        justify-content: center;
        gap: 9rem;
        margin-top: 3rem;
        button {
          width: 180px;
          height: 68px;
          border: none;
          font: 600 17px $fontFamily;
          color: $white;
          border-radius: 10px;
          background: linear-gradient(180deg, #ffb715 0%, #ff0200 98.72%);
          cursor: pointer;
        }
      }
    }
  }
}

// .appSection {
//   display: flex;
//   flex-direction: column;
//   gap: 160px;
//   width: 100%;
//   margin-bottom: 87px;
//   background-image: url("../../../public/Images/appSectionBg.png");
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center center;

//   .consumerApp {
//     display: flex;
//     padding-top: 69px;

//     .consumerAppLeft {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 50%;
//       position: relative;

//       .bgImg {
//         position: absolute;
//         z-index: -2;
//         bottom: -70px;
//         left: 84px;
//         opacity: 0.2;
//       }
//     }

//     .consumerAppRight {
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;
//       width: 50%;
//       padding-right: 168px;

//       .consumerAppHeading {
//         font: 600 36px $fontFamily;
//       }
//       .consumerAppSub {
//         font: 400 16px $fontFamily;
//       }

//       .consumerObjectives {
//         display: flex;
//         flex-direction: column;
//         gap: 18px;
//         margin-top: 26px;
//         .objectiveWrapper {
//           display: flex;
//           justify-content: flex-start;
//           align-items: center;
//           gap: 23px;

//           .objNo {
//             font: 600 26px $fontFamily;
//           }

//           .objText {
//             font: 400 1rem $fontFamily;
//             line-height: 20px;
//           }
//         }
//       }
//     }
//   }

//   .driverApp {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding-bottom: 15px;
//     .driverAppLeft {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 50%;
//       position: relative;

//       .driverBg {
//         position: absolute;
//         z-index: -2;
//         top: -200px;
//         opacity: 0.2;
//       }
//     }

//     .driverAppRight {
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;
//       width: 50%;
//       padding-left: 164px;

//       .driverAppHeading {
//         font: 600 36px $fontFamily;
//         color: $white;
//       }
//       .driverAppSub {
//         font: 400 16px $fontFamily;
//         color: rgba(255, 255, 255, 0.8);
//       }

//       .driverObjectives {
//         display: flex;
//         flex-direction: column;
//         gap: 18px;
//         margin-top: 26px;
//         .objectiveWrapper {
//           display: flex;
//           justify-content: flex-start;
//           align-items: center;
//           gap: 23px;

//           .objNo {
//             font: 600 26px $fontFamily;
//             color: $white;
//           }

//           .objText {
//             font: 400 1rem $fontFamily;
//             line-height: 20px;
//             color: rgba(255, 255, 255, 0.8);
//           }
//         }
//       }
//     }
//   }
// }

.companyDashboardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .companyDashboardHeading {
    font: 600 36px $fontFamily;
  }

  .companyDashboardSub {
    font: 400 20px $fontFamily;
    line-height: 28px;
    width: 540px;
    text-align: center;
    margin-bottom: 2rem;
  }

  .companyBgWrapper {
    position: relative;
    text-align: center;
    img {
      width: 65%;
    }

    .comBg {
      position: absolute;
      width: auto;
      opacity: 0.2;
      z-index: -2;
      top: -75px;
      right: 12%;
    }
  }

  .companyDashboardObjWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 58px;

    // padding-left: 170px;
    // padding-right: 170px;

    .comObjWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 446px;
      &:nth-child(1) {
        margin-right: 10%;
      }
      &:nth-child(3) {
        margin-top: 1rem;
        margin-right: 10%;
      }
      &:nth-child(4) {
        margin-top: 1rem;
      }

      &:nth-child(5) {
        margin-top: 1rem;
        margin-right: 10%;
      }

      p {
        &:nth-child(1) {
          font: 600 36px $fontFamily;
          color: $themeBackground;
        }

        &:nth-child(2) {
          font: 400 1rem $fontFamily;
          line-height: 22px;
          color: rgba(52, 52, 52, 0.8);
          text-align: center;
        }
      }
    }
  }
  .comDescription {
    font: 400 18px $fontFamily;
    color: rgba(52, 52, 52, 0.8);
    text-align: center;
    padding-left: 16%;
    padding-right: 16%;
  }
}

.appBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  width: 130px;
  height: 43px;
  border-radius: 8px;
  gap: 0.5rem;
  margin-bottom: 13.3rem;
  cursor: pointer;

  .appBtnInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      color: $black;
      &:nth-child(1) {
        font: 400 8px $fontFamily;
      }

      &:nth-child(2) {
        font: 600 13px $fontFamily;
        line-height: 20px;
      }
    }
  }
}
