@import "../../styles/variables";

.sidebarWrapper{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 5.4rem);
    background: linear-gradient(180deg, #FF0200 0%, #FF0200 100%);
    position: relative;

    .divider{
        width: 80%; /* Takes up 80% of the container's width */
        margin: 0 auto; /* Center the divider horizontally within the container */
        height: 0.5px; /* Adjust the divider's height as needed */
        background-color: #E2E2E2; /* Divider color */
        z-index: 200;
    }

    .navWrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
        margin-top: 4rem;
        z-index: 200;

        .activeButtonWrapper{
            display: flex;
            align-items: center;
            gap: 7px;
            padding-left: 1.3rem;
            width: 12.4rem;
            height: 3.4rem;
            background: $white;
            border-radius: $borderRadiusTwelve;
            cursor: pointer;
            p{
                font: 500 1rem $fontFamily;
                color: $themeBackground;
            }

        }
        .buttonWrapper{
            display: flex;
            align-items: center;
            gap: 7px;
            padding-left: 1.3rem;
            width: 12.4rem;
            height: 3.4rem;
            background: transparent;
            border-radius: $borderRadiusTwelve;
            cursor: pointer;
            p{
                font: 500 1rem $fontFamily;
                color: $white;
            }

            &:hover{
                background: $white;
                p{
                    color: $themeBackground;
                }
                svg{
                    color: $themeBackground !important;
                }
            }
        }
    }

    .circleShade {
        position: absolute;
        width: 446px;
        height: 446px;
        transform: rotate(-94.938deg);
        flex-shrink: 0;
        border-radius: 446px;
        border-radius: 446px;
        background: linear-gradient(154deg, #FFB715 15.56%, rgba(255, 183, 21, 0.00) 91.25%);
        bottom: -10rem;
        left: -12rem;
        z-index: 100;
      }
}
