@import "../../styles/variables";
.footerWrapper {
  width: 100%;
  background: linear-gradient(135deg, #ff0200 59.14%, #ffb715 100%);
  display: flex;
  flex-direction: column;
  position: relative;

  .footerBgimg {
    position: absolute;
    opacity: 0.2;
    bottom: 0;
    right: 0;
    z-index: 100;
  }

  .footerTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.6rem 6.5rem 2rem 9rem;
    border-bottom: 1px solid $white;
    z-index: 300;

    @media (max-width: 768px) {
      padding: 5%;
      gap: 1rem;
    }
    .topLeft {
      display: flex;
      gap: 1.8rem;
      flex-direction: column;
      align-items: flex-start;
      z-index: 200;
      @media (max-width: 768px) {
        align-items: center;
        flex-direction: row;
      }
      .footerNavWrapper {
        display: flex;
        gap: 1.4rem;
        @media (max-width: 768px) {
          gap: 1rem;
          flex-direction: column;
          width: 80px;
        }
        p {
          font: 400 15px $fontFamily;
          color: $white;
          cursor: pointer;
          @media (max-width: 768px) {
            font: 400 10px $fontFamily;
          }
        }
      }

      svg {
        @media (max-width: 425px) {
          width: 50px;
          height: 100px;
        }
      }
    }

    .topRight {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
      }
      .rightButtonsWrapper {
        display: flex;
        gap: 13px;

        @media (max-width: 768px) {
          width: 100%;
          flex-direction: column;
        }

        .qrAndBtnWrapper {
          display: flex;
          gap: 5px;

          @media (max-width: 370px) {
            flex-direction: column;
            align-items: flex-start;
          }

          img {
            width: 56px;
            height: 56px;
            @media (max-width: 768px) {
              width: 45px;
              height: 45px;
            }
          }
          button {
            width: 147px;
            height: 47px;
            border: none;
            background-color: $white;
            border-radius: $borderRadiusEight;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            @media (max-width: 768px) {
              width: 116px;
              height: 38px;
              border-radius: 5px;
            }

            span {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              p {
                &:nth-child(1) {
                  font: 400 11px $fontFamily;
                  @media (max-width: 768px) {
                    font: 400 7px $fontFamily;
                  }
                }
                &:nth-child(2) {
                  font: 700 14px $fontFamily;
                  line-height: 10px;
                  @media (max-width: 768px) {
                    font: 700 10px $fontFamily;
                  }
                }
              }
            }
          }
        }
      }

      .rightContentWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
          &:nth-child(1) {
            font: 700 1.1rem $fontFamily;
            color: $white;

            @media (max-width: 425px) {
              font: 700 0.8rem $fontFamily;
            }
          }

          &:nth-child(2) {
            font: 400 14px $fontFamily;
            color: $white;

            @media (max-width: 425px) {
              font: 400 8px $fontFamily;
            }
          }
        }
      }

      .socialWrapper {
        display: flex;
        gap: 1rem;
        align-items: center;
        @media (max-width: 425px) {
          gap: 0.5rem;
        }
        img {
          width: 35px;
          height: 35px;
          cursor: pointer;
        }
      }
    }
  }

  .footerBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.65rem 6.5rem 0.65rem 8.4rem;
    z-index: 300;

    @media (max-width: 425px) {
      flex-direction: column;
      padding-left: 5%;
      padding-right: 5%;
    }
    p {
      font: 400 14px $fontFamily;
      color: $white;

      @media (max-width: 425px) {
        font: 400 10px $fontFamily;
        text-align: center;
      }
    }

    .termsAndPolicyWrapper {
      a {
        text-decoration: none;
        color: $white;
      }
    }
  }
}
