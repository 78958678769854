@import "styles/variables";

.modalText {
    font: 500 25px $fontFamily;
    align-self: center;
  
    span {
    font: 700 25px $fontFamily;
      color: $themeBackground;
    }
  }