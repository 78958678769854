@import "../../styles/variables";

.actionButton {
  width: 80px;
  height: 33px;
  background-color: $themeBackground;
  border: none;
  border-radius: 4px;
  color: $white;
  font: 400 12px $fontFamily;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &:hover {
    background: $white;
    color: $themeBackground;
    border: 1px solid $themeBackground;
  }

  &:disabled {
    background-color: $themeBackground; /* Set your disabled background color */
    color: $white; /* Set your disabled text color */
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background: $themeBackground;
      color: $white;
      border: none;
    }
  }
}

.blockActionButton {
  width: 80px;
  height: 33px;
  background-color: $blockBtnBackground;
  border: none;
  border-radius: 4px;
  color: $white;
  font: 400 12px $fontFamily;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &:hover {
    background: $white;
    color: $themeBackground;
    border: 1px solid $themeBackground;
  }

  &:disabled { /* Set your disabled background color */
    color: $white; /* Set your disabled text color */
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      color: $white;
      border: none;
      background-color: $blockBtnBackground;
    }
  }
}
